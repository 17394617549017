import { useLayoutEffect, useState , useEffect, useRef, Fragment } from 'react'
import { gsap } from 'gsap'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'


import { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'
import { GetStaff } from '../../slices/fetchReducer'

import { StaffCard, MainLoading } from '../../components/index'
import { Iinfo, Istaff } from '../../types/interface'

const tempdata:Istaff [] = [
    {
        id: "1",
        name: "MOLLY",
        title: "GYM MANAGER",
        info: "Molly extends a warm and welcoming smile to our members, while also offering a wealth of experience in fitness and nutrition.",
        email: "molly@example.com",
        image: "/images/defaultimg.webp",        
        instagram: "https://www.instagram.com",
        category: "staff"
    },
    {
        id: "2",
        name: "HAN",
        title: "GYM ASSISTANT",
        info: "Han is our longest serving member of staff and qualified PT, bringing expertise and passion for fitness to our team!",
        email: "han@example.com",
        image: "/images/defaultimg.webp",        
        instagram: "https://www.instagram.com",
        category: "pt"
    },
    {
        id: "3",
        name: "CLAIRE AVES",
        title: "PERSONAL TRAINER & FITNESS COACH",
        info: "Claire is experienced in body transformations, weight loss, muscle building, strength & conditioning, competition prep and general fitness.",
        email: "claire@example.com",
        image: "/images/defaultimg.webp",        
        instagram: "",
        category: "pt"
    }
]


interface Iprops {
    info: Iinfo
}
const Staff = (props:Iprops) => {
    const { info } = props

    const section_ref = useRef(null)
    const image_ref = useRef(null)
    const title_ref = useRef(null)
    const [loading, setLoading] = useState(true)
    const [ staffData, setStaffData ] = useState<Istaff []>([])
    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
      FetchStaff()
    
      return () => {
        
      }
    }, [])
    
    
    const FetchStaff = async() => {
        await dispatch(GetStaff({}))
            .unwrap()
            .then( res => {
                
                if(res?.statusCode === 200){
                    setStaffData(res?.body?.data?.Staff)
                }
            })
            //setStaffData(tempdata)
    }

    useLayoutEffect(() => {
        const ctx = gsap.context(()=>{ 
            gsap.fromTo(image_ref.current,{
                filter: "blur(10px) brightness(0.8)",
            },{
                filter: "blur(0px) brightness(0.8)", 
                duration: 2,
                ease: 'power3.inOut'
            })
        
        
            gsap.fromTo(title_ref.current,{
                autoAlpha:0
            },{
                autoAlpha:1,
                duration: 2,
                ease: 'power3.inOut'
            })
            
        },section_ref)
        
        return () => {
            ctx.revert()
        };
    }, [])



    return (
        <Box className="flex__center-c "  sx={{minHeight:"100vh",/*  pt:"5rem", */ position:"relative"/* , bgcolor:"primary.dark" */}} ref={section_ref} >



                
            <Box className="flex__center-c" sx={{position:"relative", width:"100vw",height:"70vh",mb:"10rem" }}>
                <Paper className="flex__center-c" ref={title_ref}  sx={{backgroundColor:"#0000005f",zIndex:1,width:"60%",height:"60%"}}>
                    <Typography variant='h3' color="primary.contrastText" sx={{mb:"1rem"}}>
                        {info?.Staff?.Title}
                    </Typography>
                    <Typography variant='h4' color="primary.contrastText">
                        {info?.Staff?.Intro}
                    </Typography>
                </Paper>
                <img style={{width:"100%",height:"100%",objectFit:"cover",position:"absolute",filter:"blur(5px)"}} src={info?.Staff?.Image} alt="header" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)} ref={image_ref}/>   
            </Box>
                

            <Box className="flex__center-c" sx={{height:"100%",width:"100%",backgroundColor:"black",p:"5rem 0"}} >
                <Typography variant='h2' color="primary.contrastText">
                    {info?.Staff?.Title2}
                </Typography>

                <Grid className="flex__center-c maxw" container rowSpacing={20} spacing={{ xs: 2, md: 3 }} marginTop={"0.1rem"} sx={{maxWidth:"90%"}}>
                {(staffData || []).map((staffitem,i) => {        
                    if(staffitem.category === "staff"){
                        return(
                           
                            <StaffCard info={info} staff={staffitem} key={`newsitems${staffitem?.title}${i}`} />
                       
                            
                        )
                    }else{return null}         
                    
                })}
                </Grid>


                <Typography variant='h2' sx={{mt:"5rem"}} color="primary.contrastText">
                    {info?.Staff?.Title3}
                </Typography>
                    
                <Grid container rowSpacing={20} spacing={{ xs: 2, md: 3 }} marginTop={"0.1rem"} sx={{maxWidth:"90%"}}>
                {(staffData || []).map((staffitem,i) => {                
                    if(staffitem.category === "pt"){
                        return(
                            
                            <StaffCard info={info} staff={staffitem} key={`newsitems${staffitem?.title}${i}`}/>
                            
                        )
                    }else{return null}         
                })}
                </Grid>
            </Box>


              {/* LOADING SCREEN */}
              {loading && 
                <MainLoading loading={loading} info={info}  />
            }
            
        </Box>
    )
}

export default Staff