import { configureStore, combineReducers } from "@reduxjs/toolkit";
import fetchReducer from './slices/fetchReducer'


export type AppDispatch = typeof store.dispatch

const reducer = combineReducers({   
    fetchReducer: fetchReducer,
})

const store = configureStore({
    reducer: reducer,  
})



export default store

