
import React from 'react'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'

import Instagram from '@mui/icons-material/Instagram'

import { Iinfo, Istaff } from '../../types/interface'

interface Iprops {
    info: Iinfo
    staff: Istaff 
}
const StaffCard = (props:Iprops) => {
    const { info, staff } = props
 
    return (
        <Grid item xs={12} sm={6} md={4}  
              
            >
            <Card className="flex__center-c " sx={{height:"40rem",width:"20rem",justifyContent:"space-between"}}>
                
                <img 
                    src={`https://${process.env.REACT_APP_CDN_URL}/${staff?.image}`}
                     alt=""  style={{height:"50%",width:"100%", objectFit:"cover"}} 
                    onError={(news)=> process.env.REACT_APP_DEFAULTIMAGE ? news.currentTarget.src = process.env.REACT_APP_DEFAULTIMAGE : ""}
                />

                <Box width={"90%"} className="flex__center-c" sx={{justifyContent:"flex-start"}}>
                    <Typography sx={{textAlign:"center",m:"0.5rem 0"}} variant="h3">{staff?.name}</Typography>
                    <Typography color="grey.500" sx={{textAlign:"center",m:"0.5rem 0"}} variant="h5">{staff?.title}</Typography>
                    <Typography color="grey.500" sx={{textAlign:"center",mb:"2rem",
                        display: "-webkit-box",
                        overflow: "hidden",
                        "WebkitLineClamp": "6",
                        "WebkitBoxOrient": "vertical"
                    }} variant="subtitle1">{staff?.info}</Typography>                    
                </Box> 


                <Box className="flex__center-c">
                    
                    {staff?.instagram &&
                        <IconButton href={staff?.instagram || "" } target="_blank" rel="noopener"  >
                            <Instagram color='primary'/>
                        </IconButton>
                    }
                    <Typography color="grey.500" sx={{textAlign:"center",mb:"1rem"}} variant="subtitle1">{staff?.email}</Typography>
                </Box>


            </Card>
        </Grid>
    )
}

export default StaffCard