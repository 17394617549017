import { useState, useEffect, useRef} from 'react'

import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery  from '@mui/material/useMediaQuery';



// REDUX
import { GetGallery } from '../../slices/fetchReducer'; 
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'

import { Iinfo } from '../../types/interface'
import { MainLoading } from '../../components'


interface IMasonryImageList {
    galleryList: string []
}


  


interface Iprops {
    info: Iinfo
}
const Gallery = (props:Iprops) => {
    const { info } = props
    
	const dispatch: AppDispatch = useDispatch()
    

    const header_ref = useRef(null)



    //* Get Gallery Json */
    const [galleryList, setGalleryList] = useState<string []>([])
    const [loading, setLoading] = useState<boolean>(true)
    useEffect(() => {
        FetchImages()
    
      return () => {
        
      }
    }, [])
    const FetchImages = async() =>{
        await dispatch(GetGallery({}))
        .unwrap()
        .then( res => {
            
            if(res?.statusCode === 200){
                setGalleryList(res?.body?.data?.Gallery ||[])
            }else{
                setGalleryList([])
                setLoading(false)
            }
            
        })

    }




    const QuiltedImageList = (props:IMasonryImageList) => {
        const { galleryList } = props
        
        const image_ref = useRef(0)
        const matches = useMediaQuery('(max-width:690px)');
     
        const HandleLoadingImages = () => {
            image_ref.current +=1
            if(image_ref.current >= galleryList.length ){
                setLoading(false)
            }
        }
                   
        return (
          <ImageList
            sx={{ width: "100vw",m:0 }}
            variant="quilted"
            cols={ matches ? 2 : 4}
            gap={0}
      
            
          >
            {galleryList.map((item,i) => {
    
                let rows, columns;
    
                if (i % 5 === 0 || i % 5 === 5) {
                    rows = 2;
                    columns = 2;
                } else if (i % 5 === 1 || i % 5 === 2 || i % 5 === 7 || i % 5 === 8) {
                    rows = 1;
                    columns = 1;
                } else {
                    rows = 1;
                    columns = 2;
                }
            //let img = `https://${process.env.REACT_APP_CDN_URL}/${item}`
            let img = `${item}`
            return(
                <ImageListItem sx={{cursor:"pointer"}} key={`${img}${i}`} cols={columns || 1} rows={rows || 1} onClick={()=>{setOpenImageDialog(true); setSetselectedImage(img) }}>
                    <img
                        //{...srcset(img, 121, rows, columns)}
                        src={`https://${process.env.REACT_APP_CDN_URL}/${img}`}
                        alt={"gallery"}
                        loading="lazy"
                        onLoad={HandleLoadingImages}
                    />
                </ImageListItem>
            )})}
          </ImageList>
        );
    }


    //* Image Dialog for viewing fullscreen image */    
    interface FullScreenDialogProps {
        open: boolean
        image: string

    }
    const [openImageDialog, setOpenImageDialog] = useState(false)
    const [selectedImage, setSetselectedImage] = useState("")
    const FullScreenDialog = (props: FullScreenDialogProps) => {
        const { image, open } = props;

        const handleClose = () => {    
            setOpenImageDialog(false)
        };
            
        return (
        <Dialog onClose={handleClose} open={open}>
            <img                
                src={`https://${process.env.REACT_APP_CDN_URL}/${image}`}
                alt={image}
                loading="lazy"
            />
        </Dialog>
        );
    }


    return (
        <Container className="flex__center-c" maxWidth={false} ref={header_ref}  sx={{justifyContent:"flex-start",minHeight:"100vh", position:"relative", bgcolor:"", marginTop:"0 !important"}} >

            {/* Image list */}
            <QuiltedImageList galleryList={galleryList || []} />

            <FullScreenDialog open={openImageDialog} image={selectedImage}/>


            {/* LOADING SCREEN */}
            {loading && 
                <MainLoading loading={loading} info={info}  />
            }
            


        </Container>
    )
}

export default Gallery