import { useRef } from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Mui
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';

import InstagramIcon from '@mui/icons-material/Instagram';
import { IconButton } from '@mui/material';

// self
import { Logo } from '../index'
import { theme } from '../../App';
import { Iinfo, IeditInfo } from '../../types/interface'
import { colors } from '../../colors/colors';
gsap.registerPlugin(ScrollTrigger)

const NavbarStyle = {
    //position: "fixed",
    //bottom: 0, left:0,right:0,
    //margin:"0 auto",
    height: "25rem",   
    //zIndex: 5,   
    '& .MuiBreadcrumbs-separator':{
        color: "primary.main"
    
    }
   

}






interface Iprops {
    info: Iinfo | undefined
    editInfo: IeditInfo | undefined
}
const IconStyle = {
    mr:"0.2rem"
}
const Footer = (props:Iprops) => {
    const { info, editInfo } = props

    const header_ref = useRef(null)
    const matches = useMediaQuery('(max-width:690px)');
   

    return (
        <Box  component="footer" ref={header_ref} sx={{ justifyContent:"center",p:"5rem 0", backgroundColor: colors?.bg?.default }} className="flex-c" color="text.secondary" id="footer">

            <Box className="flex flex__r-c " sx={{justifyContent:"space-around",alignItems: matches ? "center":"flex-start" ,width:"95%",gap:"2rem"}}>

                {/* Logo and title */}
                <Box className="flex__center-c" sx={{flex:1}}>
                    <Typography variant='h2'  mb="0.2rem" color="primary.main">{info?.Footer?.Title1}</Typography>
                    <Logo width={6} height={6} fill={ theme.palette.primary.main }/>
                    <Typography variant='h5' mt="0.2rem" color="primary.contrastText">{info?.Footer?.Text1}</Typography>

                </Box>
                                        
                {/* Contact */}                
                <Box className="flex__center-c" sx={{flex:1,gap:"0.5rem"}}>
                    <Typography variant='h2' color="primary.dark" mb="0.2rem">{info?.Footer?.Text_Contact}</Typography>
                    <Box className="flex__center-r">
                        <LocationOnIcon sx={IconStyle} color='secondary'/>
                        <Typography variant='h5' color="primary.contrastText">{editInfo?.Footer?.Address}</Typography>
                    </Box>
                    <Box className="flex__center-r">
                        <AlternateEmailIcon sx={IconStyle} color='secondary'/>
                        <Typography variant='h5' color="primary.contrastText">{editInfo?.Footer?.Email}</Typography>
                    </Box>
                    <Box className="flex__center-r">
                        <PhoneIcon  sx={IconStyle} color='secondary'/>
                        <Typography variant='h5'  color="primary.contrastText">{editInfo?.Footer?.Phone}</Typography>
                    </Box>
                </Box>
                
                {/* OpenTimes */}
                <Box className="flex__center-c" sx={{flex:1}}>
                    <Typography variant='h2' color="primary.dark" mb="0.2rem">{info?.Footer?.Text_OpenTimes}</Typography>
                    {/* <Typography variant='subtitle1' color="primary.dark" mb="0.2rem">{info?.Footer?.SubText_OpenTimes}</Typography> */}
                    <Box className="flex__center-c" sx={{gap:"0.5rem"}}>
                        <Typography variant='h5' color="primary.contrastText" >{editInfo?.Qa?.OpenTime_Full}</Typography>
                        <Typography variant='h5' color="primary.contrastText" >{editInfo?.Qa?.OpenTime_Staff}</Typography>
                    </Box>
                    
                </Box>                                  
            </Box>

            <Box className="flex__center-r" sx={{mt:"1rem",width:"95%"}}>
                    
                <IconButton sx={{}} href={ editInfo?.Footer?.Instagram_Link || "" } target="_blank" rel="noopener">
                    <InstagramIcon fontSize='large' color='primary' />
                </IconButton>

                <IconButton sx={{}} href={editInfo?.Footer?.Facebook_Link || "" } target="_blank" rel="noopener">
                    <FacebookIcon fontSize='large' color='primary' />
                </IconButton>

                
            </Box>

            <Typography variant='h6'  sx={{width:"95%"}} color="primary.contrastText">{info?.Footer?.Designed_By}</Typography>
            
        </Box> 
    )
}

export default Footer