
import { useCallback, useRef } from 'react'
import { useLocation,useNavigate,} from 'react-router-dom';
import { createReactEditorJS } from "react-editor-js";

// tools.js
// @ts-ignore
import Embed from "@editorjs/embed";
// @ts-ignore
import Table from "@editorjs/table";
// @ts-ignore
import List from "@editorjs/list";
// @ts-ignore
import Warning from "@editorjs/warning";
// @ts-ignore
import Code from "@editorjs/code";
// @ts-ignore
import LinkTool from "@editorjs/link";
// @ts-ignore
import Image from "@editorjs/image";
// @ts-ignore
import Raw from "@editorjs/raw";
// @ts-ignore
import Header from "@editorjs/header";
// @ts-ignore
import Quote from "@editorjs/quote";
// @ts-ignore
import Marker from "@editorjs/marker";
// @ts-ignore
import CheckList from "@editorjs/checklist";
// @ts-ignore
import Delimiter from "@editorjs/delimiter";
// @ts-ignore
import InlineCode from "@editorjs/inline-code";

// mui
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';

//import { colors } from '../../colors/colors';
import { Iinfo } from '../../types/interface'
import { Logo } from '../../components';




interface Iprops {
    info: Iinfo
}
const NewsPage = (props:Iprops) => {
    const { info } = props

    const editorCore:any = useRef(null);
	const ReactEditorJS = createReactEditorJS();
    const location = useLocation()

    
    const handleInitialize = useCallback((instance:any) => {
		// await instance._editorJS.isReady;
		instance._editorJS.isReady
			.then(() => {
				// set reference to editor
				editorCore.current = instance;
			})
			.catch((err: Error) => console.log("An error occured", err));
	}, []);

  



    return (
        <Box component="section" sx={{minHeight:"100vh",justifyContent:"flex-start",pt:"5rem",position:"relative"}} className="flex__center-c " bgcolor={""/* colors?.bg?.default */} id="newspage">

                
            <Box position={"fixed"} sx={{width:"100%",height:"100%",zIndex:-1, backgroundColor:"#FFFFFFEA",inset:0}}>

            </Box>
            <Box position={"fixed"} sx={{width:"100%",height:"100%",zIndex:-2,inset:0}}>
                <Logo fill='black' />
            </Box>

       

            <Paper sx={{backgroundColor:""/*  colors?.bg.light */, width:"85%", p:"2rem" }} className=' newscomponent'>
                <Typography variant="h2" color="primary.main" sx={{textAlign:"center",m:"2rem 0"}}>
                    { location?.state?.title}
                </Typography>
                <Typography variant="subtitle1" >
                    { location?.state?.intro}
                </Typography>

              {/*   <Blocks data={{time: location?.state?.time, blocks: location.state.blocks, version:""}} 
                    config={{
                        image: {
                            className: "editorjs-img",
                            actionsClassNames: {
                                
                            }
                        },
                        paragraph: {
                            className: "editorjs-paragraph",
                            actionsClassNames: {
                                tunes: {
                                    anyTuneName: {alignment:"right"}
                                },
                              alignment: "right",                              
                            }
                        }
                    }}
                /> */}
               {/*  <Output data={ {time: location?.state?.time, blocks: location.state.blocks, version:""} } /> */}

                <ReactEditorJS   
                                     
                    onInitialize={handleInitialize}                                   
                    defaultValue={ {time: location?.state?.time, blocks: location?.state?.blocks} }                                                            
                    readOnly={true}
                    tools={{
                        // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
                        // paragraph: Paragraph,
                        embed: Embed,
                        table: Table,
                        list: List,
                        //warning: Warning,
                        code: Code,
                        linkTool: LinkTool,
                        image:  Image,                       
                        raw: Raw,
                        header: Header,
                        //quote: Quote,
                        marker: Marker,
                        checklist: CheckList,
                        delimiter: Delimiter,
                        inlineCode: InlineCode,
                    // simpleImage: SimpleImage
                    }}
                />

            </Paper>

         


        </Box>
    )
}

export default NewsPage