import { Fragment, useState, useLayoutEffect, useRef, useEffect }from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';



import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Instagram from '@mui/icons-material/Instagram';
import Facebook from '@mui/icons-material/Facebook';

// SELF IMPORTS 
//import { ReactComponent as Logo } from '../../assets/logo.svg'
import { Logo } from '../../components/index'
import { theme } from '../../App';
import { IeditInfo, Iinfo } from '../../types/interface';



gsap.registerPlugin(ScrollTrigger);

interface Iprops {
    info: Iinfo
    editInfo: IeditInfo
}
const Navbar = (props:Iprops) => {
    const { info, editInfo }  = props  
   
    const navigate = useNavigate()
    const location = useLocation()


   
    const navbar_ref = useRef(null)
    const mobile_navbar_ref = useRef(null)
  

 

    //* INTERFACE
    

    //* VARIABLES
    const [toggleNavbarMenu, setToggleNavbarMenu] = useState<boolean>(false)
    const [mobileNavbarStyle, setMobileNavbarStyle] = useState<any>({pointerEvents:"none",opacity:0,right:"-1000px"})
    const [spanStyle, setSpanStyle] = useState({span1:{},span2:{},span3:{}})

    
    //* FUNCTIONS
    const navigateClick = (location=""):any => {
        if(location){      
        navigate(location);      
        //window.scrollTo(0,0);
        }
        setMobileNavbarStyle({pointerEvents:"none",opacity:0,right:"-1000px",})   
        setSpanStyle(prev => ({...prev,
        span1:{},
        span2:{},
        span3:{}

        })) 
        
        setToggleNavbarMenu(false)
    }
    const ToggleMobileMenu = (toggle:boolean) => {
        if(toggle){
        setMobileNavbarStyle({pointerEvents:"none",opacity:0,right:"-1000px"})   
        setSpanStyle(prev => ({...prev,
            span1:{},
            span2:{},
            span3:{}

        })) 
            
        }else{
        setMobileNavbarStyle({pointerEvents:"auto",opacity:1,right:"0"})   
        setSpanStyle(prev => ({...prev,
            span1:{transform: "rotate(45deg) translate(-3px, -1px)"},
            span2:{opacity: 0, transform: "rotate(0deg) scale(0.2, 0.2)" },
            span3:{transform: "rotate(-45deg) translate(0, -1px)" }

        })) 
        }
        setToggleNavbarMenu(!toggle)
    }
   
       


    //* GSAP+
    // Fades the navbar when scrolling down and shows when scrolling up
    useLayoutEffect(() => {

        ScrollTrigger.saveStyles(navbar_ref.current);
        let mm = gsap.matchMedia();
        mm.add("(min-width: 690px)",  () => {
           
            const ctx = gsap.context(()=>{

                const showNav = gsap.fromTo(navbar_ref.current,{
                    autoAlpha: 0
                },{
                    autoAlpha: 1,
                    duration:0.4
                }).progress(1)

                
            
                ScrollTrigger.create({                   
                    start: "top top", 
                    end: "max",           
                //markers:true,         
                    onUpdate: (self) => {
                        self.direction === -1 ? showNav.play() : showNav.reverse()
                    }
                })                      
            },navbar_ref)
        
            return () => {
            ScrollTrigger.refresh()
            ctx.revert()
            };


        })
        mm.add("(max-width: 690px)",  () => {
            const ctx = gsap.context(()=>{

                const showNav = gsap.fromTo(mobile_navbar_ref.current,{
                    autoAlpha: 0
                },{
                    autoAlpha: 1,
                    duration:0.4
                }).progress(1)

      
           
                    
                
                ScrollTrigger.create({                   
                    start: "top top", 
                    end: "max",             
                     
                    onUpdate: (self) => {
                        self.direction === -1 ? showNav.play() : showNav.reverse()
                  
                    }
                    })                      
                },mobile_navbar_ref)
            
                return () => {
                    ScrollTrigger.refresh()
                    ctx.revert()
                };
        })



        return () => {
           
            mm.revert()
            };
    }, [])
    


    const matches = useMediaQuery('(max-width:690px)');
    
    // Navigation data for navbar, ex link = "/menu" and title = "Menu"
    const navbardata = [
        {
            link: info?.Navbar?.Navigation_0?.link,
            title: info?.Navbar?.Navigation_0?.title
        },
        {
            link: info?.Navbar?.Navigation_1?.link,
            title: info?.Navbar?.Navigation_1?.title
        },
        {
            link: info?.Navbar?.Navigation_2?.link,
            title: info?.Navbar?.Navigation_2?.title
        },
        {
            link: info?.Navbar?.Navigation_3?.link,
            title: info?.Navbar?.Navigation_3?.title
        }
        
    ]
    const dropdowndataMeny = {
        paths: [            
            {
                link: info?.Navbar?.Navigation_4?.link,
                title:info?.Navbar?.Navigation_4?.title
            },
            {
                link: info?.Navbar?.Navigation_5?.link,
                title:info?.Navbar?.Navigation_5?.title
            },
            {
                link: info?.Navbar?.Navigation_6?.link,
                title:info?.Navbar?.Navigation_6?.title
            }
        ],
        title: info?.Navbar?.Navigation_4?.title                       
    }
    const dropdowndataTakeaway = {
        paths: [            
            {
                link: info?.Navbar?.Navigation_2?.link,
                title:info?.Navbar?.Navigation_2?.title
            },
            {
                link: info?.Navbar?.Navigation_3?.link,
                title:info?.Navbar?.Navigation_3?.title
            }
        ],
        title: info?.Navbar?.Navigation_2?.title
    }
    const squareButton = {
        link: info?.Navbar?.Navigation_7?.link,
        title:info?.Navbar?.Navigation_7?.title        
    }
    const locationPathStyle = {     
        fullColor: {
            color: "secondary.light",
            fontWeight: "900"
        },
        fadeColor: {
            color: "primary.light",
            fontWeight: "900"
        }        
    }
    const NavbarStyle = {
        position: "fixed",
        left: 0, right: 0, top: 0,
        height: "80px",
        width:"100vw",
        justifyContent: "space-around",  
        zIndex: 5,    
    
        bgcolor: "#ffffff00",
        //boxShadow:" rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"   
    }
    const NavbarStyleMobile = {
        position: "fixed",
        left: 0, right: 0, top: 0,
        height: "80px",
        width:"100vw",
        justifyContent: "space-between",  
        zIndex: 5,    
        
    }
    const navbarDataMobile = navbardata//[navbardata,dropdowndataMeny.paths,dropdowndataTakeaway.paths,[squareButton]].flat()
    

    // if and id is set to the url, then scroll to that element
    useEffect(() => {
        const elementId = location.hash.substring(1); // Remove the leading '#' from the URL hash
        scrollToElement(elementId);
    }, [location]);    
    const scrollToElement = (elementId:string) => {
        if(elementId === "") return

        const element = document.getElementById(elementId);
        
        if (element) {
            
            setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            },500)
            
        }else{
            window.scrollTo(0,0)
        }
    };





    //* Navbar button for each navigation link 
   
    
    interface Inavbutton2 {
        path:string
        title:string
        index?:number
    }
    const NavButton2 = (props:Inavbutton2) => {           
        //const NavButton2 = (path:string, title:string,index:number) => {           
        const { path, title, index } = props
        
        const navbutton_ref = useRef(null)
        const navtext_ref = useRef(null)
        const navline_ref = useRef(null)
        const MouseHover = (path:string) => {

            if(path === location.pathname) return
            const ctx = gsap.context(()=>{

                gsap.to(navline_ref.current,{
                    width:"100%"
                })
                gsap.to(navtext_ref.current,{
                    color: theme.palette.secondary.light
                })

                return () => {                  
                    ctx.revert()
                };
                                                       
            },navbutton_ref)
        }
        const MouseLeave = (path:string) => {
            if(path === location.pathname) return
            const ctx = gsap.context(()=>{
                
                gsap.to(navline_ref.current,{
                    width:"0%"
                })
                gsap.to(navtext_ref.current,{
                    color: theme.palette.primary.light
                })

                return () => {                  
                    ctx.revert()
                };
                                                       
            },navbutton_ref)
        }
    
        
        const underLineStyle = {            
            height:"0.2rem",
            backgroundColor: theme.palette.secondary.light,
            width:"0%",                                    
        }
        
        const typoStyle = path === location.pathname ? locationPathStyle.fullColor : locationPathStyle.fadeColor
        
        return(
         
            <Box className='flex__center-c ' key={`navbutton2${title}${index}`} ref={navbutton_ref} onMouseOver={()=>MouseHover(path)} onMouseLeave={()=>MouseLeave(path)}>
                    
                
                <Button sx={{
                     '&:hover': {
                        backgroundColor: '#ffffff00',                        
                    },
                   /* '&:hover .MuiTypography-root': {                        
                        color:"primary.dark"
                    }, */
                    
                }} onClick={()=>navigateClick(path)} >
                    <Typography variant='h4' color="primary.light" sx={ typoStyle } ref={navtext_ref}>{title}</Typography>   
                </Button>
              
                <Box component="div" color="primary"/*  width={ path === location.pathname ? "100%" : "0%"  } */ sx={ underLineStyle } ref={navline_ref} />

                
              
            </Box>
          
        )
    }

    const NavButtonSquare = (props:Inavbutton2) => {           
        //const NavButton2 = (path:string, title:string,index:number) => {           
        const { path, title } = props
        
        const navbutton_ref = useRef(null)
        const navtext_ref = useRef(null)
      
        const MouseHover = (path:string) => {

            if(path === location.pathname) return
            const ctx = gsap.context(()=>{

             
                gsap.to(navbutton_ref.current,{
                    border: `0.2rem solid ${theme.palette.secondary.main}`,                  
                })
                gsap.to(navtext_ref.current,{                
                    color: theme.palette.secondary.main
                })
                return () => {                  
                    ctx.revert()
                };
                                                       
            },navbutton_ref)
        }
        const MouseLeave = (path:string) => {
            if(path === location.pathname) return
            const ctx = gsap.context(()=>{
                
             
                gsap.to(navbutton_ref.current,{
                    border: `0.2rem solid ${theme.palette.primary.light}`,                
                })
                gsap.to(navtext_ref.current,{                
                    color: theme.palette.primary.light
                })
                

                return () => {                  
                    ctx.revert()
                };
                                                       
            },navbutton_ref)
        }
    
        
      
        
        const typoStyle = path === location.pathname ? locationPathStyle.fullColor : locationPathStyle.fadeColor
        
        return(
         
            <Box className='flex__center-c ' ref={navbutton_ref} onMouseOver={()=>MouseHover(path)} onMouseLeave={()=>MouseLeave(path)} sx={{border:`0.2rem solid ${theme.palette.primary.light}`}}>
                    
                
                <Button sx={{
                     '&:hover': {
                        backgroundColor: '#ffffff00',                        
                    },
                   /* '&:hover .MuiTypography-root': {                        
                        color:"primary.dark"
                    }, */
                    
                    }} 
                    onClick={()=>navigateClick(path)} 
                >
                    <Typography variant='h4' color="primary.light" sx={ typoStyle } ref={navtext_ref}>{title}</Typography>   
                </Button>
              
                

                
              
            </Box>
          
        )
    }

    interface Inavdropdown {
        paths : {
            title: string
            link: string
        }[]
        title:string
    }
    const NavButtonDropDown = (props:Inavdropdown) => {

            //const NavButton2 = (path:string, title:string,index:number) => {           
            const { paths, title } = props
        
            const navbutton_ref = useRef(null)
            const navtext_ref = useRef(null)
       
            const dropdownbox_ref = useRef(null)
            

            const dropdownbuttons_refs = useRef(new Array(paths.length))
            
            useLayoutEffect(() => {
                const ctx = gsap.context(()=>{
    
                 
                    gsap.set(dropdownbox_ref.current,{
                        autoAlpha:0,
                        pointerEvents:"none"
                    })


                    for (let i = 0; i < dropdownbuttons_refs.current.length; i++) {
                        const ref = dropdownbuttons_refs.current[i];                        
                        gsap.set(ref,{
                            x:-100,
                            
                        })
                        
                    }
                    
                    return () => {                  
                        ctx.revert()
                    };
                                                           
                },navbutton_ref)
            }, [])
            const MouseHover = (path:string) => {
    
                
                const ctx = gsap.context(()=>{
    
                    /* gsap.to(navline_ref.current,{
                        width:"100%"
                    }) */
                    gsap.to(navtext_ref.current,{
                        color: theme.palette.secondary.light
                    })
                    gsap.to(dropdownbox_ref.current,{
                        autoAlpha:1,
                        pointerEvents:"auto"
                    })


                    for (let i = 0; i < dropdownbuttons_refs.current.length; i++) {
                        const ref = dropdownbuttons_refs.current[i];
                        const time = i*0.1
                        gsap.to(ref,{
                            x:0,
                            delay:time
                        })
                        
                    }
                    
                    return () => {                  
                        ctx.revert()
                    };
                                                           
                },navbutton_ref)
            }
            const MouseLeave = (path:string) => {
                
                const ctx = gsap.context(()=>{
                    
                    /* gsap.to(navline_ref.current,{
                        width:"0%"
                    }) */
                    gsap.to(navtext_ref.current,{
                        color: theme.palette.primary.light
                    })
                    gsap.to(dropdownbox_ref.current,{
                        autoAlpha:0,
                        pointerEvents:"none"
                    })
    
                    for (let i = 0; i < dropdownbuttons_refs.current.length; i++) {
                        const ref = dropdownbuttons_refs.current[i];
                        const time = i*0.1
                        gsap.to(ref,{
                            x:-100,
                            delay:time
                        })
                    }

                    return () => {                  
                        ctx.revert()
                    };
                                                           
                },navbutton_ref)
            }

            const MouseHoverDropDownButton = (index:number) => {
    
                
                const ctx = gsap.context(()=>{
    
                    gsap.to(dropdownbuttons_refs.current[index],{
                        color: theme.palette.secondary.light
                    })
                                        
                    return () => {                  
                        ctx.revert()
                    };
                                                           
                },navbutton_ref)
            }
            const MouseLeaveDropDownButton = (index:number,path:string) => {
                if(path === location.pathname) return
                const ctx = gsap.context(()=>{
                    

                    gsap.to(dropdownbuttons_refs.current[index],{
                        color: theme.palette.primary.light
                    })


                    return () => {                  
                        ctx.revert()
                    };
                                                           
                },navbutton_ref)
            }
                                                               
            return(
             
                <Box className='flex__center-c ' ref={navbutton_ref} onMouseOver={()=>MouseHover("")} onMouseLeave={()=>MouseLeave("")} sx={{ position:"relative"}}>                                            
                    <Button sx={{
                        '&:hover': {
                            backgroundColor: '#ffffff00',                        
                        },                                          
                        }} /* onClick={()=>navigateClick(path)}  */>
                        <Typography variant='h4' color="primary.light" sx={ {fontWeight: "900"} } ref={navtext_ref}>{title}</Typography>   
                    </Button>

                    <Box sx={{position:"absolute",  top:"100%", width:"fit-content",opacity:0, pointerEvents:"none", left:0  }} ref={dropdownbox_ref}>
                        {paths.map((item,i) => {
                            const typoStyle:any = item.link === location.pathname ? locationPathStyle.fullColor : locationPathStyle.fadeColor
                            typoStyle["textAlign"] = "left"
                            return(
                                <Button sx={{
                                    '&:hover': {
                                        backgroundColor: '#ffffff00',
                                        
                                    },
                                    '&:hover h4':{
                                        color:"secondary.main"
                                    }
                                    
                                    }} 
                                    key={`dropdownbuttons${i}`}
                                    onClick={()=>navigateClick(item?.link)} 
                                    onMouseOver={()=>MouseHoverDropDownButton(i)} onMouseLeave={()=>MouseLeaveDropDownButton(i,item?.link)}
                                    >
                                    <Typography  variant='h4' color="primary.light"  sx={ typoStyle } ref={(el)=>(dropdownbuttons_refs.current[i] = el)}>{item.title}</Typography>                                   
                                </Button>
                            )
                        })}
                        
                    </Box>
                </Box>
            )
    }

    //* Navbar button for each navigation link 
    const NavButtonMobile = (path:string, title:string,index:number, lastIndex:number) => {           

        if(lastIndex === index) {
            return(
                <Button key={`navbuttonmobile${index}`} variant="outlined" color='info' sx={{borderWidth:"0.2rem"}}
                    onClick={()=>navigateClick(path)}
                >
                    <Typography variant='h3' color="primary.contrastText" >{title}</Typography>                   
                </Button>
            )
        }else{
                return(

                <Button  variant="text" color='secondary'
                    onClick={()=>navigateClick(path)}
                    key={`NavButton${index}`}
                >
                    <Typography variant='h3' color="primary.contrastText" >{title}</Typography>   
                    
                </Button>
                
                
            )
        }
    }
  
   
    if(matches){ /* Mobile */
        return (
            <Box  className="flex__center-r mobile-navbar" sx={NavbarStyleMobile} ref={mobile_navbar_ref}>

                
                
                <Box flex={1}>
                
                </Box>
                


                {/* TITLE BUTTON */}
                <Box flex={1} className="flex__center-c" >
                    <Logo  width={5} height={5} fill={theme.palette.primary.dark}  navigateOn={true}/>                 
                </Box>
                
                    
                {/* Hamburger Menu */}
                <Box className=' flex__center-c' sx={{flex:1,zIndex:5,'& span':{backgroundColor: theme.palette.secondary.main}}}>
                    <Box className='navbar-small__icon flex__center-c' onClick={()=>ToggleMobileMenu(toggleNavbarMenu)} sx={{width:"50px",height:"50px",zIndex:5,'& span':{backgroundColor: theme.palette.grey[400]}}}>
                        <span style={spanStyle.span1}></span>
                        <span style={spanStyle.span2}></span>
                        <span style={spanStyle.span3}></span>               
                    </Box>
                </Box>      
                

                {/* Mobile Navbar */}
                <Box className='navbar-small flex__center-c' style={mobileNavbarStyle} bgcolor={"primary.dark"} >                                
                 

                    {/* TITLE BUTTON */}
                    <Box onClick={()=>navigateClick("/")} sx={{}}>                    
                        <Logo  width={10} height={10} /* fill={theme.palette.primary.light}  */ navigateOn={true}/>     
                    </Box>
                    
                    {/* NAVIGATION MENU */}                    
                    {navbarDataMobile?.map((navitem, i, {length})=> (
                        NavButtonMobile(navitem.link, navitem.title,i,length -1 )
                    ))}                                                                         
                    
                   

                    <IconButton href={editInfo?.Footer?.Instagram_Link} target="_blank" rel="noopener" sx={{width:"50px",height:"50px"}} color='info'>
                        <Instagram fontSize='large' fill='#ffffff !important' />
                    </IconButton>   

                </Box>

          
               

            </Box>
        )
    }else {    
        return (
            <Box className='flex__center-r desktop-navbar' ref={navbar_ref} sx={NavbarStyle}>
            
                {/* TITLE BUTTON */}             
                <Logo height={5} width={5} fill={ theme.palette.primary.dark } navigateOn={true} />


                {/* NAVIGATION MENU */}
                <Box className='flex__center-r' sx={{alignItems:"flex-start"}}>                
                    {navbardata?.map((navitem, i)=> (
                        <Box key={`navbuttonkey${i}`}>
                            <NavButton2 path={navitem.link}  title={navitem.title} index={i}  />
                        </Box>
                    ))}     

                  {/*   <NavButtonDropDown paths={ dropdowndataMeny?.paths }  title={ dropdowndataMeny?.title}   />

                    <NavButtonDropDown paths={ dropdowndataTakeaway?.paths }  title={ dropdowndataTakeaway?.title}   />
 */}
                  

                </Box>{/*  */}


                {/* Social media icons */}
                <Box className='flex__center-r'>                
                   {/*  <NavButtonSquare path={squareButton?.link}  title={squareButton?.title}   />   */}          
                   <IconButton href={editInfo?.Footer?.Instagram_Link || "" } target="_blank" rel="noopener"  >
                        <Instagram color='secondary'/>
                    </IconButton>

                    <IconButton href={editInfo?.Footer?.Instagram_Link || "" } target="_blank" rel="noopener"  >
                        <Facebook color='secondary'/>
                    </IconButton>
                    
                   
                </Box>

          

            </Box>
        )
    }
}

export default Navbar