import React from 'react'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import useMediaQuery  from '@mui/material/useMediaQuery'
import { IeditInfo, Iinfo } from '../../types/interface'
import { Typography } from '@mui/material'

import { Pricing } from '../../components/index'

interface Iprops {
    info: Iinfo
    editInfo: IeditInfo
}
const Qa = (props:Iprops) => {
    const { info, editInfo } = props
    const matches = useMediaQuery('(max-width:690px)');
    return (
        <Box className={"flex__center-c"}  sx={{p: "5rem 0",position:"relative",minHeight:"100vh",width:"100vw"}}>

            <img  src={info?.Qa?.Image_bg} style={{zIndex:-1,width:"100%",height:"100%",objectFit:"cover",position:"fixed",inset:0}} alt=""/>
            <Box sx={{position:"absolute",inset:0,backgroundColor:"#ffffffdb",zIndex:0}} />

            <Box className={"flex__center-c"} zIndex={1}> 

                {/* Price */}
                <Box width={"90%"} className="flex__center-c" sx={{mb:"4rem"}}>
                    <Typography variant='h2' textAlign={"center"} sx={{mb:"2rem"}}>
                        {info?.Qa?.Title_Price}                    
                    </Typography>
                    <Typography variant='h4' textAlign={"center"} sx={{maxWidth: matches?"90%":"60%"}}>
                        {info?.Qa?.SubText_Price}                
                    </Typography>

                <Pricing info={info} pricingArr={editInfo?.Qa?.Pricing}/>

                </Box>


                {/* OpenTimes */}
                <Box width={"90%"} className="flex__center-c">

                    <Typography variant='h2' textAlign={"center"} sx={{mb:"2rem"}}>
                        {info?.Qa?.Title_Open}
                    </Typography>

                    <Typography variant='h4' textAlign={"center"} sx={{maxWidth:"60%"}}>                        
                        {editInfo?.Qa?.OpenTime_Full}
                    </Typography>
                    {/* <Typography variant='h4' textAlign={"center"} sx={{maxWidth:"60%",mb:"2rem"}}>

                    </Typography> */}

                    <Typography variant='h4' textAlign={"center"} sx={{maxWidth:"60%"}}>
                        {editInfo?.Qa?.OpenTime_Staff}
                    </Typography>
                    {/* <Typography variant='h4' textAlign={"center"} sx={{maxWidth:"60%"}}>
                        
                    </Typography> */}
                                        
                </Box>


                {/* Questions */}
                <Box width={"90%"} sx={{mt:"5rem"}} className="flex__center-c">

                    <Typography variant='h2' textAlign={"center"} sx={{mb:"2rem"}}>
                        {info?.Qa?.Title_Questions}
                    </Typography>

                    <Typography variant='h5' textAlign={"center"} sx={{maxWidth:"60%"}}>                        
                        {editInfo?.Qa?.Questions}
                    </Typography>
                    
                                        
                </Box>


            </Box>
            

        </Box>
    )
}

export default Qa