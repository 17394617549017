import { useEffect, useState} from 'react';
import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';


import StarIcon from '@mui/icons-material/StarBorder';

import Typography from '@mui/material/Typography';

import { Iinfo, Ipricing } from '../../types/interface';


interface Iprops {
    pricingArr: Ipricing []
    info: Iinfo
}
const Pricing = (props:Iprops)  =>{
    const { pricingArr, info } = props;

    const [ price, setPrice ] = useState(pricingArr || [])

    useEffect(() => {
      setPrice(pricingArr || [])
    
      return () => {
        
      }
    }, [pricingArr])
    
 
    return (            
        
        <Box sx={{width:"100%"}}  component="main">
            <Box className="flex__center-r" sx={{flexWrap:"wrap"}}>
            {price.map((tier,i) => (
            
                <Card sx={{m:"2rem",width:"17rem"}} key={`pricing${tier?.title}${i}`}>
                    <CardHeader
                    title={tier.title}
                    //subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    //action={tier.title === 'Helår' ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                        align: 'center',
                    }}
                    sx={{
                        height:"5rem",
                        backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[700],
                    }}
                    />
                    <CardContent>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'baseline',
                        mb: 2,
                        }}
                    >
                        <Typography component="h2" variant="h4" color="text.primary" textAlign={"left"}>
                        {tier.price_young}  
                        </Typography>
                     
                    </Box>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'baseline',
                        mb: 2,
                        }}
                    >
                        <Typography component="h2" variant="h4" color="text.primary" textAlign={"left"}>
                        {tier.price_adult}
                        </Typography>                                                                
                    </Box>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'baseline',
                        mb: 2,
                        }}
                    >
                        <Typography component="h2" variant="h4" color="text.primary" textAlign={"left"}>
                        {tier.price_senior}
                        </Typography>                                                                
                    </Box>
                    {/*  <ul>
                        {tier.description.map((line) => (
                        <Typography
                            component="li"
                            variant="subtitle1"
                            align="center"
                            key={line}
                        >
                            {line}
                        </Typography>
                        ))}
                    </ul> */}
                    </CardContent>
                    <CardActions>
                    {/*    <Button
                        fullWidth
                        variant={tier.buttonVariant as 'outlined' | 'contained'}
                    >
                        {tier.buttonText}
                    </Button> */}
                    </CardActions>
                </Card>
                
            ))}
            </Box>
        </Box>
        
     

    );
}
export default Pricing