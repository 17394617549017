import React from 'react'
import { Iinfo } from '../../types/interface'
import Box from '@mui/material/Box'
import useMediaQuery  from '@mui/material/useMediaQuery'

import { TripleSectionInfo } from '../../components/index'
interface Iprops {
    info: Iinfo
}
const Intro = (props:Iprops) => {
    const { info } = props
    const matches = useMediaQuery('(max-width:690px)');
    return (
        <Box className={matches ? "flex__center-c" : "flex"}  sx={{minHeight:"100vh", position:"relative"/* , bgcolor:"primary.dark" */}} /* ref={section_ref}  */>
            {info?.Intro?.info.map((item,i) => {
              //  const dir = ? "left" : "right"
                return(
                    <TripleSectionInfo info={item} key={`introitem${item?.title1}${i}`} dir={ i % 2 === 0}/>
                )
            })}
            
        </Box>
    )
}

export default Intro