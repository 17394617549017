
import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useMediaQuery  from '@mui/material/useMediaQuery'

import CheckIcon from '@mui/icons-material/Check'; 
import { colors } from '../../colors/colors'

interface Iprops {
    info:{
        title1: string
        title2: string
        text1: string
        image1_bg_trans: string
        image1: string
        title3: string
        subtitle3: string
        text3: string

    },
    dir: boolean
}

const TripleSectionInfo = (props:Iprops) => {
    const { info,dir } = props
    const matches = useMediaQuery('(max-width:690px)');

    return (
        <Box sx={{width: matches ? "100vw" :"50vw",height: matches ? "100%" : "100vh",backgroundColor: colors?.bg?.default}} className="flex__center-c">

            {/* text and bgimg transperant */}
            <Box className="flex__center-c" sx={{order:matches ?  1 : dir ? 1: 2  , position:"relative",p: matches ? "5rem 0": "0", height:"52%" }} width={"100%" } >

                <Box sx={{position:"absolute",inset:0,backgroundColor:"#ffffffdb",zIndex:1}} />

                

                {dir ?                  
                <Box zIndex={3} sx={{width:"90%"}}>
                    <Typography  sx={{letterSpacing:"0.05em",textTransform:"uppercase",fontWeight:900,textAlign:"left",width:"90%"}} variant='h2'>{info?.title1}</Typography>
                    <Typography  sx={{letterSpacing:"0.05em",fontWeight:900,textAlign:"left",width:"90%"}} variant='h2'>{info?.title2}</Typography>
                    <Typography color="grey.800" sx={{textAlign:"left",width:"90%"}} variant='subtitle1'>{info?.text1}</Typography>
                </Box>
                :
                <Box zIndex={3} sx={{width:"90%"}}>
                    <Typography color="grey.800" className='flex'  sx={{alignItems:"center",textAlign:"left",width:"90%",m:"1rem 0"}} variant='h3'> <CheckIcon color='primary'/> {info?.title1}</Typography>
                    <Typography color="grey.800" className='flex'  sx={{alignItems:"center",textAlign:"left",width:"90%" ,m:"1rem 0"}} variant='h3'>  <CheckIcon color='primary'/> {info?.title2}</Typography>
                    <Typography color="grey.800"  className='flex'  sx={{alignItems:"center",textAlign:"left",width:"90%",m:"1rem 0"}} variant='h3'>  <CheckIcon color='primary'/> {info?.text1}</Typography>
                </Box>                
                }

                <img  src={info?.image1_bg_trans} style={{zIndex:0,width:"100%",height:"100%",objectFit:"cover",position:"absolute",inset:0}} alt=""/>

            </Box>

            <Box className={matches ? "flex__center-c" : "flex"}  width={"100%"} sx={{order: matches ? 2: dir ? 2: 1,height:"48%"}}>
                {/* bgimg  */}
                <Box className="flex__center-c" sx={{position:"relative", width: matches ? "100%" :"50%" ,backgroundColor: colors?.bg?.default}} >

                    <img src={info?.image1} alt="" style={{width: matches ? "90%":"100%",height:"100%",objectFit:"cover"}}/>

                </Box>

                {/* text  */}
                <Box className="flex__center-c" sx={{ width: matches ? "100%" :"50%" ,backgroundColor: colors?.bg?.default,p: matches ? "5rem 0": "0"}} >

                    <Typography color="primary.contrastText" sx={{textAlign:"left",width:"90%"}} variant='h2'>{info?.title3}</Typography>
                    <Typography  color='primary.main' sx={{textAlign:"left",width:"90%"}} variant='subtitle1'>{info?.subtitle3}</Typography>
                    <Typography color="grey.400" sx={{textAlign:"left",width:"90%"}} variant='subtitle1'>{info?.text3}</Typography>

                </Box>
            </Box>

        </Box>
    )

    return (
        <Box sx={{width:"100%",height: matches ? "100%" : "50vh",backgroundColor: colors?.bg?.default}} className="flex flex__r-c">

            {/* text and bgimg transperant */}
            <Box className="flex__center-c" sx={{order: dir ? 1: 3  , position:"relative",p: matches ? "5rem 0": "0" }} width={matches ? "100%" : "50%"} >

                <Box sx={{position:"absolute",inset:0,backgroundColor:"#ffffffdb",zIndex:1}}>

                </Box>

                <Box zIndex={3} sx={{width:"90%"}}>
                    <Typography  sx={{textAlign:"left",width:"90%"}} variant='h2'>{info?.title1}</Typography>
                    <Typography  sx={{textAlign:"left",width:"90%"}} variant='subtitle1'>{info?.title2}</Typography>
                    <Typography  sx={{textAlign:"left",width:"90%"}} variant='subtitle1'>{info?.text1}</Typography>
                </Box>

                <img  src={info?.image1_bg_trans} style={{zIndex:0,width:"100%",height:"100%",objectFit:"cover",position:"absolute",inset:0}} alt=""/>

            </Box>

            {/* bgimg  */}
            <Box className="flex__center-c" sx={{order:2,position:"relative", height:"50vh", width: matches ? "100%" : "25%",backgroundColor: colors?.bg?.default}} >

                <img src={info?.image1} alt="" style={{width: matches ? "90%":"100%",height:"100%",objectFit:"cover"}}/>

            </Box>

            {/* text  */}
            <Box className="flex__center-c" sx={{order: dir ? 3 : 1 ,width: matches ? "100%" : "25%",backgroundColor: colors?.bg?.default,p: matches ? "5rem 0": "0"}} >

                <Typography sx={{textAlign:"left",width:"90%"}} variant='h2'>{info?.title3}</Typography>
                <Typography sx={{textAlign:"left",width:"90%"}} variant='subtitle1'>{info?.subtitle3}</Typography>
                <Typography sx={{textAlign:"left",width:"90%"}} variant='subtitle1'>{info?.text3}</Typography>

            </Box>

        </Box>
    )
}

export default TripleSectionInfo