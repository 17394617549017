import { useRef, useState, useLayoutEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import {gsap} from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FacebookProvider, CustomChat } from 'react-facebook';


import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import { MainLoading, Logo } from '../../components'
import { Iinfo } from '../../types/interface'
import { theme } from '../../App'

gsap.registerPlugin(ScrollTrigger);
interface Iprops {
    info: Iinfo
}

const Welcomepage = (props:Iprops) => {
    const { info } = props
    const [loading, setLoading] = useState(true)

    const header_ref = useRef(null)
    const bg_image_ref = useRef(null)
    

    const navigate = useNavigate()

    const imageStyle = {
        width: "100vw"
    }

    //* GSAP
    useLayoutEffect(() => {
    
        const ctx = gsap.context(()=>{ 
        
        gsap.to(bg_image_ref.current,{
            yPercent:50,
            ease:"none",
            scrollTrigger:{
                trigger:header_ref.current,
                start:"top top",
                end:"bottom top",
                scrub:true,
                //markers:true,
        
            }
        })
    
            
        },header_ref)

        return () => {
        
        ScrollTrigger.refresh()
        ctx.revert()
        };

        
    }, [])



    return (
        <Box component="section" className="flex__center-c" ref={header_ref} sx={{ height:"100vh",borderBottom: `2px solid ${theme?.palette?.primary?.main}`}} >

            {/* Image */}
            
            <img style={{width:"100vw"}} ref={bg_image_ref} className='header-img-noblur'  src={info?.Welcomepage?.Image} alt="header" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)}/>   
            

      

            <Box sx={{p:"2rem"}} className="flex-c">
                <Logo width={25} height={25} fill={ theme.palette.primary.contrastText }/>
                <Typography variant="h1" sx={{textAlign:"center"}} color="primary.contrastText">{info?.Welcomepage?.Title1}</Typography>
                <Typography variant="h1" sx={{textAlign:"center"}} color="primary.contrastText">{info?.Welcomepage?.Title2}</Typography>                
            </Box>

            <FacebookProvider appId="393648583217194" chatSupport>
                {/* <SendToMessenger messengerAppId="393648583217194" pageId="61555681183819"/> */}
                <CustomChat pageId="213054091893651" minimized={false}/>
            </FacebookProvider> 
      {/*       <MessengerCustomerChat
                pageId="61555681183819"
                appId="393648583217194"
               
            /> */}

        
           

            {/* LOADING SCREEN */}
            {loading && 
                <MainLoading loading={loading} info={info}  />
            }
            

            
        </Box>
    )
}

export default Welcomepage