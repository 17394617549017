import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// mui
import Pagination from '@mui/material/Pagination';
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'


import NavigateNextIcon from '@mui/icons-material/NavigateNext';

//* REDUX */
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'
import { GetNews } from '../../slices/fetchReducer'

// self
import { Iinfo, Inewsitem } from '../../types/interface'
import { Logo, MainLoading, usePagination } from '../../components/index'






//* MAIN  */   
interface Iprops {
    info: Iinfo       
}
const News = (props:Iprops) => {    		   
    const { info } = props

    const dispatch:AppDispatch = useDispatch()
    const image_ref = useRef(0)
    const [ loading, setLoading ] = useState(true)
    const [newsAll, setNewsAll] = useState<Inewsitem []>([])
    const header_ref = useRef(null)

    const itemsPerPage = 4
    const numberOfPages = Math.ceil(newsAll?.length / itemsPerPage)
    const [page, setPage] = useState(1)
    const _DATA = usePagination(newsAll, itemsPerPage);
    const ChangePage = (e:any,p:number) => {        
        window.scrollTo(0,0)
        setPage(p)
        _DATA.jump(p)
    }
 
    
    useEffect(()=>{
        FetchNews()
    },[])

    const FetchNews = async() =>{
        try {
                    
            await dispatch(GetNews({}))
                .unwrap()
                .then(res => {
                    
                    if(res?.statusCode === 200){
                        const data = res?.body?.data.sort((a:any,b:any)=> b.createdOn - a.createdOn) || []
                        setNewsAll(data)
                    }else{
                        setLoading(false)
                        setNewsAll([])
                    }
                    
                })
                .catch(err=> console.log(err))
        } catch (error) {
            setLoading(false)
            setNewsAll([])
        }

    }

    interface InewsComponent {
        info: Iinfo
        news: Inewsitem     
    }
    const NewsComponent = (props:InewsComponent) => {
        const { news, info } = props
    
        
        const navigate = useNavigate()
    
        const HandleLoadingImages = () => {
         
            image_ref.current +=1
            if(image_ref.current >= newsAll.length ){
                setLoading(false)
            }
        }
    
        return (
            <Grid item xs={12} sm={6} md={4} className="flex__center-c " key={`newscomponent${news?.id}`}
                sx={{
                    
                    flexDirection:"column !important",
                    justifyContent:"flex-start",
                                                   
                }}
            >
    
    
                {/* Image */}
                {/* <Box className="news-image-box" sx={{marginBottom:"2rem"}}>
                    <img 
                        src={`https://${process.env.REACT_APP_CDN_URL}/${news?.images[0]}`}
                        alt={news?.title}
                       
                    />
                </Box> */}
                <Box className="" sx={{marginBottom:"2rem"}}>
                    <img 
                        src={`https://${process.env.REACT_APP_CDN_URL}/${news?.thumbnail}`}
                        //src={`${news?.images[0]}`}
                        alt={news?.title}                    
                        //style={{height: '30vmax', width: '25vmax', objectFit:"cover"}}
                        style={{ objectFit:"cover"}}
                        onError={(news)=> process.env.REACT_APP_DEFAULTIMAGE ? news.currentTarget.src = process.env.REACT_APP_DEFAULTIMAGE : ""}
                        onLoad={HandleLoadingImages}
                    />
                </Box>
    
    
                {/* Text Area */}
                <Box className="flex__center-c">
    
                    {/* Title */}
                    <Typography variant='h4' color="secondary" paragraph>
                        {news?.title}
                    </Typography>
    
                    {/* Info text */}
                    <Typography className='wrap-3-row' maxWidth={"100%"} variant='subtitle1' paragraph  >
                        {news?.intro}
                    </Typography>
    
    
                    {/* read more button */}
                    <Button endIcon={<NavigateNextIcon  color="secondary"  sx={{width:"1.5vmax",height:"1.5vmax"}}/>} onClick={()=>navigate(`/news/${news?.id}`,{state:news}) }>
                        <Typography  variant='button' color="secondary"  >
                            {info?.News?.ReadMore}
                        </Typography>
                    </Button>
                    
                </Box>
    
            </Grid>
        )
    }
    

    return (
        <Container component="section" className="flex__center-c" maxWidth={false} ref={header_ref}  sx={{minHeight:"100vh", position:"relative",alignItems:"center"/* , bgcolor:"primary.dark" */}} >

            <Box position={"fixed"} sx={{width:"100%",height:"100%",zIndex:-1, backgroundColor:"#FFFFFFEA",inset:0}}>

            </Box>
            <Box position={"fixed"} sx={{width:"100%",height:"100%",zIndex:-2,inset:0}}>
                <Logo fill='black' />
            </Box>

            
            <Grid className="maxw" container rowSpacing={20} spacing={{ xs: 2, md: 3 }} marginTop={"10rem !important"} >
                {_DATA?.currentData()?.map((item,i)=>{
                    const news = item as Inewsitem;
               
                    return(
                        <NewsComponent {...{news,info}} key={`newscomponent${news.id}`}/>
                    )
                })}



            </Grid>

            <Pagination 
                count={numberOfPages}
                color="secondary"
                page={page}
                onChange={ChangePage}               
                sx={{m:"1rem 0"}}
            />  
           


            {/* LOADING SCREEN */}
            {loading && 
                <MainLoading loading={loading} info={info}  />
            }

        </Container>
    )
}

export default News