export const colors = {
    bg: {
        default: "#1e1e1e",
        light:"#454545",
        dark: "#2c2c2c",
        //default:"rgba(57, 68, 81, 0.5)"
    },
    taskfield:{
        default: "#505e6c76"
    },
    taskfield_hover:{
        default: "#7690ab76"
    },
    taskfield_completed:{
        default: "#272e3476"
    },
    taskfield_completed_hover:{
        default: "#54667a76"
    },
    weathericonsweek:{
        default: "#2f384176"
    },
    weathericonsweek_hover:{
        default: "#63758776"
    }

}