import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'







//const serverAddress = process.env.NODE_ENV === "production" ? `https://api.${process.env.REACT_APP_COMPONENT}.se` : "http://localhost:5000"
const serverAddress =  `https://api.${process.env.REACT_APP_COMPONENT}.se` 

export const GetGallery = createAsyncThunk(
    "get/GetGallery",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/gallery/gallery.json`,
        {            
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
    return { body: {data: res.data}, statusCode: res.status}
    } catch (error) {
        console.log(error)   
        return {statusCode:400}
    }
})
export const GetInfo = createAsyncThunk(
    "get/GetInfo",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`${serverAddress}/getinfo`)        
        return res.data
    } catch (error) {
        console.log(error)   
        return {statusCode:400}
    }
})

export const GetNews = createAsyncThunk(
    "get/GetNews",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`${serverAddress}/getnews/`)
        return res.data
    } catch (error) {
        console.log(error)   
        return {statusCode:400}
    }
})
export const GetStaff = createAsyncThunk(
    "get/GetStaff",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/staff/staff.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
        return { body: {data: res.data}, statusCode: res.status}
    } catch (error) {
        console.log(error)   
        return {statusCode:400}
    }
})
export const GetEditInfo = createAsyncThunk(
    "get/GetEditInfo",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/info/editinfo.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
        return { body: {data: res.data}, statusCode: res.status}
    } catch (error) {
        console.log(error)   
        return {statusCode:400}
    }
})


const initalStateValue = { AccToken:"" , user:{}, status: "inital", refreshingToken: false, info:{}}


const postSlice = createSlice({
    name:"fetchData",
    initialState: initalStateValue,
    reducers:{},
    extraReducers: builder => {       
        builder.addCase(GetInfo.fulfilled,(state, action) => {
            state.info = action?.payload?.body?.data
            
        })
      
        builder.addMatcher((action) => action.type.endsWith('/pending'),(state,action)=>{                          
            state.status = 'loading'
        })  
        builder.addMatcher((action) => action.type.endsWith('/fulfilled'),(state,action)=>{                             
            state.status = 'succeeded'
        })  
        builder.addMatcher((action) => action.type.endsWith('/rejected'),(state,action)=>{                          
            state.status = 'failed'
        })
    }
})

const { reducer } = postSlice

export default reducer

 